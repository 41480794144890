"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const ui_kit_web_admin_1 = require("@directsoftware/ui-kit-web-admin");
const ConfirmLink = ({ action = () => false, actionArgs, children, confirmMessage = 'Are you sure that you want to do this?', confirmText = 'Yes please', showConfirm: showConfirmProp = false, ctaVariation = 'secondary', ctaAppearance = 'outline', ctaLabel = 'Confirm', ctaSize = 'default', showConfirmOnClick, useDesignKitCTA, title, isDanger }) => {
    const [showConfirm, setShowConfirm] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setShowConfirm(showConfirmProp);
    }, [showConfirmProp]);
    function showHideConfirm() {
        setShowConfirm(prev => !prev);
        showConfirmOnClick && showConfirmOnClick();
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        useDesignKitCTA ? (react_1.default.createElement(ui_kit_web_admin_1.CallToActionButton, { onClick: () => setShowConfirm(true), appearance: ctaAppearance, variation: ctaVariation, size: ctaSize }, ctaLabel)) : (react_1.default.createElement("span", { style: { display: 'inline-block' }, className: "ReactConfirmLink", onClick: showHideConfirm }, children)),
        react_1.default.createElement(ui_kit_web_admin_1.ConfirmDialog, { reveal: showConfirm, confirmOnClick: () => {
                if (actionArgs) {
                    action(actionArgs);
                }
                else {
                    action();
                }
                showHideConfirm();
            }, description: confirmMessage, title: title, confirmLabel: confirmText, cancelOnClick: showHideConfirm, closeOnClick: showHideConfirm, isDanger: isDanger })));
};
exports.default = ConfirmLink;
