import React from 'react';
import SubportfolioSelect from 'adminComponents/Selects/SubportfolioSelect';
import PortfolioSelect from 'adminComponents/Selects/PortfolioSelect';
import SubportfolioService from 'adminApi/SubportfolioService';
import { OrganizationContext } from 'adminContexts';

export default class AddUnitGroupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      portfolioId: null
    };
  }

  componentDidMount() {
    this.loadSubportfolios();
  }

  loadSubportfolios = () => {
    this.setState({ loading: true });
    SubportfolioService.list(this.context.organizationId, this.props.filter)
      .then(response => {
        this.setState({
          loading: false,
          data: response.rows
        });
      })
      .catch(err =>
        displayError({ message: 'Error loading Subportfolios', err })
      );
  };

  setSubportfolioDefaultValue = options => {
    if (options.length === 1) return options[0];

    return null;
  };

  renderSub() {
    if (this.state.portfolioId === this.props.portfolioId) {
      return <SubportfolioSelect
        onSelect={this.props.handleSubSelect}
        label="Parent Sub Portfolio"
        defaultSelectData={{
          where: [`portfolio_id = ${this.props.portfolioId}`],
          select: ['id', 'name'],
          order: ['name']
        }}
      />
    } else {
      this.setState({ portfolioId: this.props.portfolioId })
      return <div />;
    };

  }

  render() {
    return (
      <div className="align-left">
        <form noValidate>
          <div>
            <label htmlFor="name">Name</label>
            <input
              className="magnified"
              type="text"
              name="name"
              onChange={this.props.onChange}
              value={this.props.name}
            />
          </div>
          <div>
            <div className="select-wrapper magnified">
              <PortfolioSelect
                onSelect={e => { !this.state.portfolioId && this.setState({ portfolioId: e.id }); this.props.handlePortfolioSelect(e); }}
                label="Parent Portfolio"
              />
            </div>
            {this.props.portfolioId ? (
              <div className="select-wrapper magnified">
                {this.renderSub()}
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

AddUnitGroupModal.contextType = OrganizationContext;
