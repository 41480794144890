import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CallToActionLink,
  FlexBox,
  Panel,
  Pill,
  Spacer,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';

const WebsiteCard = ({
  siteName,
  language,
  currency,
  brandId,
  isLegacyCMS,
  defaultBrand
}) => {
  return (
    <FlexBox className="websiteCard__cardWrapper">
      <Panel className="websiteCard__card" padding="s">
        <FlexBox alignItems="center" gap="xs">
          {defaultBrand && (
            <Box style={{ marginTop: -8 }}>
              <Pill color="purple">Default</Pill>
            </Box>
          )}
          <Box flex="1" isTextTruncateWrapper>
            <TextH2 truncate textColor="dark-gray" weight="semibold">
              {siteName}
            </TextH2>
          </Box>
        </FlexBox>
        <FlexBox gap="xs">
          {isLegacyCMS && (
            <>
              <Box>
                <TextDense>Legacy CMS</TextDense>
              </Box>
              <Box>&bull;</Box>
            </>
          )}
          <Box>
            <TextDense>{`Language: ${language.toUpperCase()}`}</TextDense>
          </Box>
          <Box>&bull;</Box>
          <Box>
            <TextDense>{`Currency: ${currency.toUpperCase()}`}</TextDense>
          </Box>
        </FlexBox>
        <Spacer size="l" />
        <FlexBox justifyContent="flex-end" alignItems="center">
          <FlexBox justifyContent="flex-end" alignItems="center" gap="xs">
            <CallToActionLink
              href={`/brands/${brandId}${isLegacyCMS ? '/pages' : ''}`}
              variation="secondary"
              appearance="ghost"
              size="dense"
            >
              {isLegacyCMS ? 'Manage Pages' : 'Settings'}
            </CallToActionLink>
            <CallToActionLink
              href={`/brands/${brandId}${isLegacyCMS ? '' : '/pages'}`}
              variation="secondary"
              appearance="outline"
              size="dense"
            >
              {isLegacyCMS ? 'Manage Site' : 'Pages CMS'}
            </CallToActionLink>
          </FlexBox>
        </FlexBox>
      </Panel>
    </FlexBox>
  );
};

WebsiteCard.propTypes = {
  siteName: PropTypes.string,
  language: PropTypes.string,
  currency: PropTypes.string,
  brandId: PropTypes.number
};

export default WebsiteCard;