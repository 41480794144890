import React from 'react';

import SimpleSelect from './SimpleSelect';

const defaultSelectData = {
  model: 'Portfolio',
  select: ['id', 'name'],
  order: 'name'
};

const PortfolioSelect = props => (
  <SimpleSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={props.label}
    placeholder={'Select...'}
    name={props.name}
    isClearable={props.isClearable}
    value={props.value}
    {...props}
  />
);

export default PortfolioSelect;

PortfolioSelect.defaultProps = {
  isClearable: true,
  label: 'Portfolio',
  name: 'portofolio_id'
};
