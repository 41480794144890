const propertySingleSideTabAnchors = {
  overview: [
    {
      label: "Images",
      hash: "propertyImages"
    },
    {
      label: "Property Info",
      hash: "propertyInfo"
    },
    {
      label: "Location",
      hash: "propertyLocation"
    },
    {
      label: "Headlines & Descriptions",
      hash: "propertyDescription"
    },
    {
      label: "Lodging Info",
      hash: "propertyLodging"
    },
    {
      label: "Emergency Contact",
      hash: "propertyEmergencyContact"
    }
  ],
  policiesAndRules: [
    {
      label: "Check-in Instructions",
      hash: "propertyCheckInInstructions"
    },
    {
      label: "Accommodations & Terms",
      hash: "propertyAccomodations"
    },
    {
      label: "Guest Controls",
      hash: "propertyGuestControls",
      checkPermissionTag: "guest_controls"
    },
    {
      label: "Internet Options",
      hash: "propertyInternetOptions"
    }
  ],
  peopleAndAdmin: [
    {
      label: "iCal Imports",
      hash: "propertyIcal"
    },
    {
      label: "Associated Websites",
      hash: "propertyAssociatedWebsites"
    },
    {
      label: "Contracts",
      hash: "propertyContracts"
    },
    {
      label: "Payees",
      hash: "propertyPayees"
    },
    {
      label: "Work Orders",
      hash: "propertyWorkOrders"
    },
    {
      label: "Team Members",
      hash: "propertyTeamMembers"
    },
    {
      label: "Notes",
      hash: "propertyNotes"
    }
  ],
  availability: [
    {
      label: "Default Availability",
      hash: "propertyDefaultAvailability"
    },
    {
      label: "Booking Window",
      hash: "propertyBookingWindow"
    },
    {
      label: "Min/Max Stay",
      hash: "propertyMinMaxStay"
    },
    {
      label: "Check-in & Check-out",
      hash: "propertyCheckInCheckOut"
    }
  ],
  pricing: [
    {
      label: "Nightly Pricing",
      hash: "propertyNightlyPricing"
    },
    {
      label: "Taxes",
      hash: "propertyTaxes"
    },
    {
      label: "Extended Stay Pricing",
      hash: "propertyExtendedStayPricing"
    },
    {
      label: "Security Deposit",
      hash: "propertySecurityDeposit"
    },
    {
      label: "Fees",
      hash: "propertyFees"
    },
    {
      label: "Deductions",
      hash: "propertyDeductions"
    },
    {
      label: "Booking Deposit",
      hash: "propertyBookingDeposit"
    },
    {
      label: "Refund Policies",
      hash: "propertyRefundPolicies"
    }
  ]
};
const propertyMultiUnitSideTabAnchors = {
  overview: [
    {
      label: "Images",
      hash: "propertyImages"
    },
    {
      label: "Property Info",
      hash: "propertyInfo"
    },
    {
      label: "Location",
      hash: "propertyLocation"
    },
    {
      label: "Property Description",
      hash: "propertyDescription"
    }
  ],
  policiesAndRules: [
    {
      label: "Accommodations & Terms",
      hash: "propertyAccomodations"
    },
    {
      label: "Guest Controls",
      hash: "unitGuestControls",
      checkPermissionTag: "guest_controls"
    },
    {
      label: "Internet Options",
      hash: "unitInternetOptions"
    }
  ],
  peopleAndAdmin: [
    {
      label: "Payees",
      hash: "propertyPayees"
    },
    {
      label: "Team Members",
      hash: "propertyTeamMembers"
    },
    {
      label: "Notes",
      hash: "propertyNotes"
    }
  ]
};
const propertyChildUnitSideTabAnchors = {
  overview: [
    {
      label: "Images",
      hash: "unitImages"
    },
    {
      label: "Unit Info",
      hash: "unitInfo"
    },
    {
      label: "Unit Location",
      hash: "unitLocation",
      checkPermissionTag: "unit-location"
    },
    {
      label: "Headlines & Descriptions",
      hash: "unitDescription"
    }
  ],
  policiesAndRules: [
    {
      label: "Check-in Instructions",
      hash: "unitCheckInInstructions"
    },
    {
      label: "Guest Controls",
      hash: "unitGuestControls",
      checkPermissionTag: "guest_controls"
    },
    {
      label: "Internet Options",
      hash: "unitInternetOptions"
    }
  ],
  bedsAndBaths: [
    {
      label: "Bedrooms",
      hash: "unitBedrooms"
    },
    {
      label: "Bathrooms",
      hash: "unitBathrooms"
    }
  ],
  peopleAndAdmin: [
    {
      label: "iCal Imports",
      hash: "propertyIcal"
    },
    {
      label: "Associated Websites",
      hash: "propertyAssociatedWebsites"
    },
    {
      label: "Work Orders",
      hash: "propertyWorkOrders"
    }
  ]
};
const vehicleSideTabAnchors = {
  overview: [
    {
      label: "Images",
      hash: "vehicleImages"
    },
    {
      label: "Vehicle Info",
      hash: "vehicleInfo"
    },
    {
      label: "Location",
      hash: "vehicleLocation"
    },
    {
      label: "Vehicle Description",
      hash: "vehicleDescription"
    },
    {
      label: "Delivery Ranges",
      hash: "vehicleDeliveryRanges"
    },
    {
      label: "Delivery Locations",
      hash: "vehicleDeliveryLocations"
    },
    {
      label: "Vehicle Details",
      hash: "vehicleDetails"
    },
    {
      label: "Vehicle Features",
      hash: "vehicleTowableFeatures"
    }
  ],
  policiesAndRules: [
    {
      label: "Suitability Configuration",
      hash: "vehicleSuitabilityConfiguration"
    },
    {
      label: "Vehicle Rules",
      hash: "vehicleRules"
    }
  ],
  availability: [
    {
      label: "Default Availability",
      hash: "vehicleDefaultAvailability"
    },
    {
      label: "Booking Window",
      hash: "vehicleBookingWindow"
    },
    {
      label: "Min/Max Stay",
      hash: "vehicleMinMaxStay"
    },
    {
      label: "Pick-up & Drop-off",
      hash: "vehiclePickUpDropOff"
    }
  ],
  pricing: [
    {
      label: "Nightly Pricing",
      hash: "vehicleNightlyPricing"
    },
    {
      label: "Taxes",
      hash: "vehicleTaxes"
    },
    {
      label: "Extended Stay Pricing",
      hash: "vehicleExtendedStayPricing"
    },
    {
      label: "Security Deposit",
      hash: "vehicleSecurityDeposit"
    },
    {
      label: "Fees",
      hash: "vehicleFees"
    },
    {
      label: "Usages",
      hash: "vehicleUsages"
    },
    {
      label: "Deductions",
      hash: "vehicleDeductions"
    },
    {
      label: "Payment Terms",
      hash: "vehiclePaymentTerms"
    },
    {
      label: "Refund Policies",
      hash: "vehicleRefundPolicies"
    }
  ]
};
export {
  propertySingleSideTabAnchors,
  propertyMultiUnitSideTabAnchors,
  propertyChildUnitSideTabAnchors,
  vehicleSideTabAnchors
};
