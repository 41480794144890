import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallToActionLink,
  FormField,
  IconFontAwesome,
  IconLink,
  PageHeader,
  PopoverForm,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { FeatureTierDisplay } from 'src/admin/components/FeatureTierDisplay';
import { useDetectMobile } from 'src/shared/hooks';
import { updateActiveRoutes } from 'reduxSlices/navigation';
import { WEBSITE_NAV_TAGS } from 'sharedNav';
import { ajaxDataWithAuthorization } from 'apiClient';
import { dispatchError } from 'src/admin/components';
import { updateBrandsUi } from 'src/admin/redux/slices/brands';

const SiteEditorHome = props => {
  const { isTouch } = useDetectMobile();
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [defaultBrand, setDefaultBrand] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const saveDefaultBrand = () => {
    setIsSaving(true);
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'PATCH',
        url: `/api/organizations/${props.organization.id}`,
        context: this,
        dataType: 'json',
        data: {
          default_brand_id: defaultBrand.value
        }
      })
    )
      .done(() => {
        setIsSaving(false);
        dispatch(updateBrandsUi({ refreshBrandList: true }));
      })
      .fail(jqXhr => {
        dispatchError();
        console.warn(jqXhr);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WEBSITE_NAV_TAGS.PRIMARY,
        secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR
      })
    );
    setBrands(
      props.brands.map(brand => {
        const option = {
          label: brand.name,
          value: brand.id
        };
        if (brand.default) {
          setDefaultBrand(option);
        }
        return option;
      })
    );
  }, []);

  const { isRVshareUser } = useSelector(state => state.userDetails);
  const newBrandPath = '/brands/new';
  const newBrandPathV2 = `${newBrandPath}?cms_version=v2`;

  const renderPageHeader = () => {
    return (
      <PageHeader>
        <PageHeader.Left>Website Editor</PageHeader.Left>
        <PageHeader.Right>
          <>
            <PopoverForm
              dropdownAlignment="bottomRight"
              triggerType="icon-button"
              triggerLabel={<IconFontAwesome name="cog" />}
              triggerProps={{
                variation: 'secondary',
                appearance: 'ghost',
                size: isTouch ? 'dense' : 'default'
              }}
              primaryActionProps={{
                action: () => saveDefaultBrand(),
                label: 'Update Default Brand'
              }}
              showSpinner={isSaving}
            >
              <FormField
                labelText="Default Brand"
                inputType="select"
                inputProps={{
                  value: brands.filter(
                    brand => brand.value === defaultBrand.value
                  )[0],
                  options: brands,
                  onChange: option => setDefaultBrand(option)
                }}
              />
            </PopoverForm>
            <VisibilityHelper hideStartingFrom="tablet">
              <IconLink href={newBrandPathV2} size="dense">
                <IconFontAwesome name="plus" />
              </IconLink>
            </VisibilityHelper>
            <VisibilityHelper showStartingFrom="tablet">
              <CallToActionLink
                href={newBrandPathV2}
                prependIcon={<IconFontAwesome name="plus" />}
              >
                Create
              </CallToActionLink>
            </VisibilityHelper>
          </>
        </PageHeader.Right>
      </PageHeader>
    );
  };

  return (
    <>
      {isRVshareUser ? (
        <FeatureTierDisplay allowedTiers={['premium']}>
          {renderPageHeader()}
        </FeatureTierDisplay>
      ) : (
        renderPageHeader()
      )}
    </>
  );
};

export default SiteEditorHome;