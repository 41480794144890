import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import { IconFontAwesome, PopoverForm } from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';
import NewPortfolio from './new-portfolio';

export default class ListPortfoliosCommandPalette extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      name: '',
      stripe_connect_account_id: null,
      lynnbrook_account_id: null,
      isDisabled: true,
      isProcessing: false
    };
  }

  onSubmit = e => {
    this.setState({ isProcessing: true });
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/portfolios/`,
        context: this,
        dataType: 'json',
        data: {
          name: this.state.name.trim(),
          stripe_connect_account_id: this.state.stripe_connect_account_id,
          lynnbrook_account_id: this.state.lynnbrook_account_id
        }
      })
    )
      .done(function(data) {
        this.props.loadPortfolios();
        this.setState({
          name: '',
          stripe_connect_account_id: null,
          lynnbrook_account_id: null,
          isDisabled: true,
          isProcessing: false
        });
      })
      .fail(function(jqXhr) {
        const response = jqXhr.responseJSON;
        this.setState({ isProcessing: false });
        toast.error(response.errors);
      });
  };

  onChange = e => {
    const stateChange = {};
    if (e?.target) stateChange[e.target.name] = e.target.value;
    else stateChange[e.name] = e.value;
    this.setState(stateChange, () => this.checkIfCanSubmit());
  };

  checkIfCanSubmit = () => {
    this.setState({
      isDisabled: this.state.name === ''
    });
  };

  onCancel = closePortal => {
    closePortal();
  };

  render() {
    return (
      <>
        <PopoverForm
          triggerType={
            this.props.changeTrigger ? 'icon-button' : 'call-to-action-button'
          }
          triggerProps={
            this.props.changeTrigger
              ? { size: 'dense' }
              : { prependIcon: <IconFontAwesome name="plus" /> }
          }
          triggerLabel={
            this.props.changeTrigger ? (
              <IconFontAwesome name="plus" />
            ) : (
              'Create'
            )
          }
          primaryActionProps={{
            actionType: 'button',
            action: () => this.onSubmit(),
            label: 'Create',
            isDisabled: this.state.isDisabled || this.state.isProcessing
          }}
          dropdownAlignment="bottomRight"
          showSpinner={this.state.isProcessing}
        >
          <NewPortfolio
            stripe_connect_account_id={this.state.stripe_connect_account_id}
            lynnbrook_account_id={this.state.lynnbrook_account_id}
            name={this.state.name}
            organization={this.props.organization}
            trustAccounts={this.props.trustAccounts}
            reloadPortfolios={this.props.loadPortfolios}
            onChange={this.onChange}
          />
        </PopoverForm>
      </>
    );
  }
}
