// Dependencies
// -----------------------------------------------
import React from 'react';
import deepcopy from 'deepcopy';
import displayError from 'sharedComponents/ErrorDisplay';
import PortalModal from 'sharedComponents/PortalModal';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { BedroomService } from 'adminApi';

// Components
// -----------------------------------------------
import {
  Box,
  CallToActionButton,
  EditableLineItem,
  InputText,
  Label,
  Modal,
  TextH2,
  InputNumber,
  FlexBox,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { checkPermissions } from 'sharedNav/helpers';

// -----------------------------------------------
// COMPONENT->INDIVIDUAL-BEDROOM-FIELDS ----------
// -----------------------------------------------
export default class IndividualBedroomFields extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name: this.props.bedroom.name,
      type:
        this.props.bedroom.bedroom_type === 'living_sleeping'
          ? 'Living Room'
          : 'Bedroom',
      defaultName: `${
        this.props.bedroom.bedroom_type === 'living_sleeping'
          ? 'Living Room'
          : 'Bedroom'
      } ${this.props.index + 1}`,
      description: this.props.bedroom.description,
      amenities: this.props.bedroom.amenities,
      amenities_cache: this.props.bedroom.amenities,
      editing: false,
      isDirty: false,
      dirtyBeds: [],
      totalBedsWasExceeded: false,
      openModal: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.index !== this.props.index) {
      this.setState({
        defaultName: `${
          this.props.bedroom.bedroom_type === 'living_sleeping'
            ? 'Living Room'
            : 'Bedroom'
        } ${this.props.index + 1}`
      });
    }
  }
  
  newTotalBedsForBedroom = amenities =>
    Object.entries(amenities).reduce(
      (sum, amenity) => sum + amenity[1].value,
      0
    );

  onChange = e => {
    e.preventDefault();
    const stateChange = { [e.target.name]: e.target.value };
    if (this.state.totalBedsWasExceeded) {
      this.setState(stateChange);
    } else {
      this.setState({ ...stateChange, isDirty: true });
    }
  };

  toggleEditing = e => {
    e.preventDefault();
    this.setState({
      editing: !this.state.editing,
      isDirty: false
    });
  };

  toggleEditingAndSave = closePortal => {
    if (
      this.newTotalBedsForBedroom(this.state.amenities) +
        this.props.totalNumBeds >
      this.props.numSleep
    ) {
      this.setState({ totalBedsWasExceeded: true });
      return toast.warn(
        'The total number of beds in individual rooms may not exceed the number of guests this property permits'
      );
    } else {
      const p = this.saveBedroom();
      p.then(_data => {
        this.setState({
          amenities_cache: this.state.amenities,
          edit: false,
          isDirty: false,
          dirtyBeds: [],
          openModal: false
        });
        if (closePortal) closePortal();
      }).catch(err => displayError({ message: 'Error saving bedroom', err }));
    }
  };

  toggleEditingAndRevert = closePortal => {
    this.setState({ amenities: this.state.amenities_cache, edit: false });
    closePortal();
  };

  updateAmenity = e => {
    const amenities = deepcopy(this.state.amenities);
    amenities[e.currentTarget.name].value =
      typeof e.currentTarget.value === 'string'
        ? parseInt(e.currentTarget.value)
        : e.currentTarget.value;
    this.setState({
      isDirty: true,
      dirtyBeds: [...this.state.dirtyBeds, e.target.name],
      totalBedsWasExceeded: false,
      amenities
    });
  };

  saveBedroom = () => {
    if (this.state.dirtyBeds.length > 0) {
      this.state.dirtyBeds.map(type => {
        if (isNaN(this.state.amenities[type].value)) {
          this.state.amenities[type].value = 0;
        }
      });
    }

    return BedroomService.updateBedroom({
      orgId: this.props.organization.id,
      vehicleId: this.props.vehicle?.id,
      unitId: this.props.unit?.id,
      bedroomId: this.props.bedroom.id,
      fromUnit: this.props.fromUnit,
      data: {
        name: this.state.name ? this.state.name : null,
        amenities: JSON.stringify(this.state.amenities),
        description: this.state.description
      }
    });
  };

  getNumBeds() {
    let numBeds = 0;
    for (const key in this.state.amenities) {
      numBeds += parseInt(this.state.amenities[key].value);
    }
    if (numBeds === 0) {
      return 'No beds.';
    } else if (numBeds === 1) {
      return `${numBeds} bed.`;
    } else {
      return `${numBeds} beds.`;
    }
  }

  getAmenityBed = (label, value) => {
    if (value > 1) {
      return `${value} ${label}s`;
    } else {
      return `${value} ${label}`;
    }
  };

  renderEditing() {
    return (
      <div>
        <fieldset>
          <section>
            <TextH2>{this.getName()}</TextH2>
            <Label>{`${this.state.type} name`}</Label>
            <InputText
              name="name"
              placeholder={this.state.defaultName}
              onChange={this.onChange}
              value={this.state.name}
            />
            <Spacer />
            <TextH2>Beds</TextH2>
            <FlexBox flexWrap="wrap">
              {Object.keys(this.state.amenities).map(fieldKey => (
                <div style={{ width: '50%' }} key={fieldKey}>
                  <Label htmlFor={fieldKey}>
                    {this.state.amenities[fieldKey].label}
                  </Label>
                  <InputNumber
                    name={fieldKey}
                    onChange={this.updateAmenity}
                    value={this.state.amenities[fieldKey].value}
                    min={0}
                    inputWidth="s"
                  />
                </div>
              ))}
            </FlexBox>
            <Spacer />
            <TextH2>Description</TextH2>
            <Spacer size="xxs" />
            <InputText
              onChange={this.onChange}
              name="description"
              value={this.state.description}
              maxLength="60"
            />
            {this.state.description && (
              <div>
                Characters left:
                {60 - this.state.description.length}
              </div>
            )}
          </section>
        </fieldset>
      </div>
    );
  }

  renderViewing() {
    return (
      <div>
        <div>
          {get(this, 'state.amenities.AMENITY_BUNK_BED.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_BUNK_BED.label'),
              get(this, 'state.amenities.AMENITY_BUNK_BED.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_CHILD_BED.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_CHILD_BED.label'),
              get(this, 'state.amenities.AMENITY_CHILD_BED.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_CRIB.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_CRIB.label'),
              get(this, 'state.amenities.AMENITY_CRIB.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_DOUBLE.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_DOUBLE.label'),
              get(this, 'state.amenities.AMENITY_DOUBLE.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_KING.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_KING.label'),
              get(this, 'state.amenities.AMENITY_KING.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_MURPHY_BED.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_MURPHY_BED.label'),
              get(this, 'state.amenities.AMENITY_MURPHY_BED.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_QUEEN.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_QUEEN.label'),
              get(this, 'state.amenities.AMENITY_QUEEN.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_SLEEP_SOFA.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_SLEEP_SOFA.label'),
              get(this, 'state.amenities.AMENITY_SLEEP_SOFA.value')
            )}
        </div>
        <div>
          {get(this, 'state.amenities.AMENITY_TWIN_SINGLE.value') > 0 &&
            this.getAmenityBed(
              get(this, 'state.amenities.AMENITY_TWIN_SINGLE.label'),
              get(this, 'state.amenities.AMENITY_TWIN_SINGLE.value')
            )}
        </div>
        <br />
        <div>{get(this, 'state.description')}</div>
      </div>
    );
  }

  onCancel = closePortal => (
    <CallToActionButton
      onClick={() => this.toggleEditingAndRevert(closePortal)}
      variation="secondary"
      appearance="ghost"
    >
      Cancel
    </CallToActionButton>
  );

  onSubmit = closePortal => (
    <CallToActionButton
      onClick={() => this.toggleEditingAndSave(closePortal)}
      isDisabled={!this.state.isDirty}
    >
      Save
    </CallToActionButton>
  );

  renderHeaderViewing() {
    const name = this.getName();

    return (
      <header className="input-group-header">
        <h3>{name}</h3>
        <div className="button-container">
          <PortalModal
            openByClickOn={openPortal => (
              <CallToActionButton
                appearance="outline"
                variation="secondary"
                onClick={openPortal}
              >
                Edit
              </CallToActionButton>
            )}
            header={`Editing ${this.state.type}`}
            submitAction={this.onSubmit}
            cancelAction={this.onCancel}
            closeOnSubmit
            actionStyles={{ display: 'flex', flexDirection: 'row-reverse' }}
            disableCloseOnOutsideClick
          >
            {this.renderEditing()}
          </PortalModal>
        </div>
      </header>
    );
  }

  getName() {
    if (this.state.name) {
      return this.state.name;
    } else if (this.props.bedroom.bedroom_type == 'living_sleeping') {
      return `Common area`;
    } else {
      return this.state.defaultName;
    }
  }

  render() {
    return (
      <>
        <EditableLineItem
          label={this.getName()}
          customDisplayView={this.renderViewing()}
          primaryActionEventProps={{
            action: () => this.setState({ openModal: true })
          }}
          primaryActionType={
            checkPermissions(
              this.props.user,
              'view_properties',
              'properties_read_only'
            )
              ? 'button'
              : 'none'
          }
        />
        <Modal
          reveal={this.state.openModal}
          title="Edit Bedroom"
          closeOnClick={() => this.setState({ openModal: false })}
        >
          <Modal.Content contentIsScrollable>
            <Box padding="s">{this.renderEditing()}</Box>
          </Modal.Content>
          <Modal.Footer
            primaryLabel="Save"
            primaryIsDisabled={!this.state.isDirty}
            primaryOnClick={() => this.toggleEditingAndSave()}
            secondaryLabel="Cancel"
            secondaryOnClick={() => this.setState({ openModal: false })}
          />
        </Modal>
      </>
    );
  }
}
