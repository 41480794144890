const isPhoneNoValid = (value) => {
  const pattern = /^[- +()]*[0-9][- +()0-9]*/;
  if (pattern.test(value) && value.length >= 10)
    return true;
  else
    return false;
};
const isEmailValid = (value) => {
  const pattern = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  if (pattern.test(value))
    return true;
  else
    return false;
};
const isPhoneNumberValid = (value) => {
  const pattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return pattern.test(value);
};
const normalizeInputProps = (type, props, inputChangeOverride) => {
  const inputProps = { ...props };
  if (type === "select") {
    inputProps.onChange = inputChangeOverride ? (opt) => inputChangeOverride(opt) : inputProps.onChange;
  }
  if (type === "number") {
    inputProps.addSuffix = inputProps.suffix || inputProps.addSuffix;
  }
  return inputProps;
};
export {
  isEmailValid,
  isPhoneNoValid,
  isPhoneNumberValid,
  normalizeInputProps
};
