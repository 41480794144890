import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  ui: {
    refreshBrandList: false
  }
};
const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    updateBrandsUi: (state, action) => {
      return { ...state, ui: { ...state.ui, ...action.payload } };
    },
    resetBrandsUi: (state) => {
      return { ...state, ui: initialState.ui };
    }
  }
});
export const { updateBrandsUi, resetBrandsUi } = brandsSlice.actions;
export default brandsSlice.reducer;
export const selectBrandsUiState = createSelector((state) => state.brands, (brands) => brands.ui);
