const workOrderTypeOptions = [
  { label: "Cleaning", value: "cleaning" },
  { label: "Concierge", value: "concierge" },
  { label: "Guest Services", value: "guest_services" },
  { label: "Inspection", value: "inspection" },
  { label: "Maintenance", value: "maintenance" },
  { label: "Other", value: "other" }
];
const jobTypeOptions = [
  { value: "damage", label: "Damage" },
  { value: "repair", label: "Repair" },
  { value: "preventative", label: "Preventative" }
];
const workOrderStatusOptions = [
  { value: "cancelled", label: "Cancelled", color: "#111111" },
  { value: "rejected", label: "Rejected", color: "#FB5872" },
  { value: "accepted", label: "Accepted", color: "#FFC349" },
  { value: "dispatched", label: "Dispatched", color: "#FFC349" },
  { value: "active", label: "In Progress (Job Started)", color: "#FFC349" },
  { value: "pending", label: "Requested", color: "#cccccc" },
  {
    value: "completed_progress",
    label: "In Progress (Job Complete)",
    color: "#FFC349"
  },
  { value: "completed_complete", label: "Complete", color: "#50E3C2" },
  { value: "completed_review", label: "In Review", color: "#387BD2" },
  {
    value: "completed_changes",
    label: "Changes Requested",
    color: "#FB5872"
  }
];
export { workOrderTypeOptions, jobTypeOptions, workOrderStatusOptions };
