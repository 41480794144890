import React, { useEffect } from 'react';
import {
  CallToActionLink,
  IconFontAwesome,
  IconLink,
  PageHeader,
  Tooltip,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import PermissionComponent from 'PermissionComponent';
import { useDetectMobile } from 'src/shared/hooks';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const PropertiesHome = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PROPERTIES
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Listings: <span>Properties</span>
      </PageHeader.Left>
      <PermissionComponent
        user={props.user}
        permission="view_properties"
        restriction="properties_read_only"
      >
        <PageHeader.Right>
          <Tooltip tipContent="Bulk Edit">
            <IconLink
              href="/bulk-edit/properties"
              variation="secondary"
              appearance="ghost"
              size={isMobile ? 'dense' : 'default'}
            >
              <IconFontAwesome name="layerGroup" />
            </IconLink>
          </Tooltip>
          <VisibilityHelper hideStartingFrom="tablet">
            <IconLink href="/properties/new" size="dense">
              <IconFontAwesome name="plus" />
            </IconLink>
          </VisibilityHelper>
          <VisibilityHelper showStartingFrom="tablet">
            <CallToActionLink
              href="/properties/new"
              prependIcon={<IconFontAwesome name="plus" />}
            >
              Create
            </CallToActionLink>
          </VisibilityHelper>
        </PageHeader.Right>
      </PermissionComponent>
    </PageHeader>
  );
};

export default PropertiesHome;
