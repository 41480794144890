import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import AddPromosButton from 'src/admin/pages/DashboardPromotions/components/AddPromosButton';
import { updateListings } from 'src/admin/redux/slices/listings';
import { useDetectMobile } from 'src/shared/hooks';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const Promotions = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PROMOTIONS
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Listings: <span>Promotions</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <AddPromosButton
          organization={props.organization}
          reloadPromotions={() =>
            dispatch(updateListings({ refreshPromotions: true }))
          }
          triggerChange={isMobile}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default Promotions;
