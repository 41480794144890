import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  FormField,
  FormFieldGridRow,
  Label,
  Spacer,
  TextBody,
  TextTitle
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import Script from 'react-load-script';
import { useDetectMobile } from 'sharedHooks';
import {
  selectNewOrganization,
  updateNewOrganization
} from 'reduxSlices/createOrganization';

const CreateOrganizationBusinessLocation = ({ handleDisableNextButton }) => {
  const dispatch = useDispatch();
  const organization = useSelector(selectNewOrganization);
  const geoSuggest = useRef();
  const { isMobile } = useDetectMobile();

  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [mapsLoading, setMapsLoading] = useState(true);
  const [geoInputFocused, setGeoInputFocused] = useState(false);

  const handleMapScriptError = () => {
    setMapsLoaded(false);
    setMapsLoading(false);
  };

  const handleMapScriptLoad = () => {
    setMapsLoaded(true);
    setMapsLoading(false);
  };

  const updateOnSuggestSelected = suggest => {
    const adrComponents = suggest.gmaps.address_components;
    const adrPostalCode = adrComponents.find(e => {
      return e.types[0] === 'postal_code';
    });
    const adrCountry = adrComponents.find(e => {
      return e.types[0] === 'country';
    });
    const adrState = adrComponents.find(e => {
      return e.types[0] === 'administrative_area_level_1';
    });
    const adrCity = adrComponents.find(e => {
      return e.types[0] === 'locality';
    });
    const adrStreetName = adrComponents.find(e => {
      return e.types[0] === 'route';
    });
    const adrStreetNumber = adrComponents.find(e => {
      return e.types[0] === 'street_number';
    });
    const finalStreetValue = `${
      adrStreetNumber ? adrStreetNumber.long_name : ''
    } ${adrStreetName ? adrStreetName.long_name : ''}`;
    dispatch(
      updateNewOrganization({
        adrStreet: finalStreetValue,
        adrUnit: '',
        adrCity: adrCity ? adrCity.long_name : '',
        adrState: adrState ? adrState.long_name : '',
        adrCountry: adrCountry ? adrCountry.long_name : '',
        adrPostalCode: adrPostalCode ? adrPostalCode.long_name : ''
      })
    );
    geoSuggest.current.update(finalStreetValue);
  };

  useEffect(
    () => {
      handleDisableNextButton(
        organization.adrStreet === '' ||
          organization.adrCity === '' ||
          organization.adrState === '' ||
          organization.adrCountry === '' ||
          organization.adrPostalCode === ''
      );
    },
    [organization]
  );

  return (
    <Box className="newOrganization__formWrapper" paddingBottom="l">
      <TextTitle>Business Location</TextTitle>
      <Spacer />
      <TextBody>Please provide your business’s physical address</TextBody>
      <Divider padding="s" />
      {mapsLoading ? (
        <></>
      ) : (
        <>
          {mapsLoaded ? (
            <FormFieldGridRow
              formFields={[
                <Box>
                  <Label>Street Address</Label>
                  <Geosuggest
                    types={['geocode']}
                    initialValue={organization.adrStreet}
                    ref={geoSuggest}
                    onSuggestSelect={updateOnSuggestSelected}
                    inputClassName="newOrganization__textInput"
                    className={`newOrganization__textInputWrapper${
                      geoInputFocused ? ' focused' : ''
                    }`}
                    suggestsClassName="newOrganization__suggestsWrapper"
                    suggestItemClassName="newOrganization__suggestItem"
                    onFocus={() => setGeoInputFocused(true)}
                    onBlur={() => setGeoInputFocused(false)}
                  />
                </Box>,
                {
                  gridRowCellWidth: isMobile ? '100%' : 75,
                  component: {
                    labelText: 'Apt/Suite',
                    inputType: 'text',
                    inputProps: {
                      onChange: e => {
                        dispatch(
                          updateNewOrganization({ adrUnit: e.target.value })
                        );
                      },
                      value: organization.adrUnit
                    }
                  }
                }
              ]}
            />
          ) : (
            <FormFieldGridRow
              formFields={[
                {
                  component: {
                    labelText: 'Street Address',
                    inputType: 'text',
                    inputProps: {
                      onChange: e => {
                        dispatch(
                          updateNewOrganization({ adrStreet: e.target.value })
                        );
                      },
                      value: organization.adrStreet
                    }
                  }
                },
                {
                  gridRowCellWidth: isMobile ? '100%' : 75,
                  component: {
                    labelText: 'Apt/Suite',
                    inputType: 'text',
                    inputProps: {
                      onChange: e => {
                        dispatch(
                          updateNewOrganization({ adrUnit: e.target.value })
                        );
                      },
                      value: organization.adrUnit
                    }
                  }
                }
              ]}
            />
          )}

          <FormFieldGridRow
            formFields={[
              {
                component: {
                  labelText: 'City',
                  inputType: 'text',
                  inputProps: {
                    onChange: e => {
                      dispatch(
                        updateNewOrganization({ adrCity: e.target.value })
                      );
                    },
                    value: organization.adrCity
                  }
                }
              },
              {
                gridRowCellWidth: isMobile ? '100%' : 110,
                component: {
                  labelText: 'State/Province',
                  inputType: 'text',
                  inputProps: {
                    onChange: e => {
                      dispatch(
                        updateNewOrganization({ adrState: e.target.value })
                      );
                    },
                    value: organization.adrState
                  }
                }
              },
              {
                gridRowCellWidth: isMobile ? '100%' : 125,
                component: {
                  labelText: 'Postal Code',
                  inputType: 'text',
                  inputProps: {
                    onChange: e => {
                      dispatch(
                        updateNewOrganization({ adrPostalCode: e.target.value })
                      );
                    },
                    value: organization.adrPostalCode
                  }
                }
              }
            ]}
          />
          <FormField
            labelText="Country"
            inputType="text"
            inputProps={{
              onChange: e => {
                dispatch(updateNewOrganization({ adrCountry: e.target.value }));
              },
              value: organization.adrCountry
            }}
          />
        </>
      )}
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBXCHyNY4lkhgRSiFppOTN5uR83-rNpeE8&libraries=places"
        onError={handleMapScriptError}
        onLoad={handleMapScriptLoad}
      />
    </Box>
  );
};

CreateOrganizationBusinessLocation.propTypes = {
  handleDisableNextButton: PropTypes.func
};

CreateOrganizationBusinessLocation.defaultProps = {};

export default CreateOrganizationBusinessLocation;
