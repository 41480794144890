import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import PortalModal from 'sharedComponents/PortalModal';
import MentionsTextBox from 'adminComponents/mentions-textbox';
import debounce from 'lodash/debounce';
import { UserService } from 'adminApi';
import { Spinner } from 'adminComponents';
import {
  Box,
  CallToActionButton,
  IconFontAwesome,
  Modal
} from '@directsoftware/ui-kit-web-admin';
import { dispatchError } from '../../../components/error-alert';
import "./index.css";

export default class ManagePropertyNewNote extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      mentioned: false,
      mentionees: null,
      textareaValue: '',
      users: [],
      sending: false,
      openModal: false
    };
  }

  componentDidMount = () => {
    UserService.list(this.props.organization.id)
      .then(response => {
        this.setState({ users: response.mention_users });
      })
      .catch(err =>
        displayError({ message: 'Error fetching employees', autoClose: 5000 })
      );
  };

  addMentionee = debounce((mentions, value) => {
    this.setState({ mentionees: mentions, textareaValue: value });
  }, 500);

  createNewNote = (e, closePortal) => {
    e.preventDefault();
    this.setState({ sending: true });
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/notes`,
        context: this,
        dataType: 'json',
        data: {
          notable_type: 'Property',
          message: this.state.textareaValue,
          notable_id: this.props.property.id,
          mentionees: this.state.mentionees
        }
      })
    )
      .done(function(data) {
        this.props.fetchNotes();
        this.setState({ sending: false, openModal: false }, () => {
          window.location.reload(); 
        });
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.error(jqXhr);
      });
  };

  handleModal = () => {
    this.setState(prevState => ({ openModal: !prevState.openModal }));
  };

  render() {
    return (
      <div style={{marginBottom: "10px"}}>
        <CallToActionButton
          onClick={this.handleModal}
          appendIcon={<IconFontAwesome name="plus" />}
        >
          Add a note
        </CallToActionButton>

        <Modal
          reveal={this.state.openModal}
          title="New Note"
          closeOnClick={this.handleModal}
          disableCloseOnOutsideClick
        >
          <Modal.Content>
            <Box padding="l" style={{ padding: '15px' }}>
              {this.state.sending ? (
                <Spinner />
              ) : (
                <MentionsTextBox
                  organization={this.props.organization}
                  addMentionee={this.addMentionee}
                  users={this.state.users}
                />
              )}
            </Box>
          </Modal.Content>
          <Modal.Footer
            primaryLabel="Save"
            primaryOnClick={this.createNewNote}
            secondaryLabel="Cancel"
            secondaryOnClick={this.handleModal}
          />
        </Modal>
      </div>
    );
  }
}
