/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import displayError from 'sharedComponents/ErrorDisplay';
import { ajaxDataWithAuthorization } from 'apiClient';
import { Redirect } from 'react-router-dom';
import { IconFontAwesome, PopoverForm } from '@directsoftware/ui-kit-web-admin';
import AddRateGroupModal from './AddRateGroupModal';

export default class AddRateGroupButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      subportfolio_id: null,
      portfolio_id: null,
      id: null,
      isSubmitted: false,
      isProcessing: false,
      reveal: false
    };
  }

  onSubmit = e => {
    this.setState({ isProcessing: true });
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/rate_groups/`,
        context: this,
        dataType: 'json',
        data: {
          name: this.state.name,
          subportfolio_id: this.state.subportfolio_id,
          portfolio_id: this.state.portfolio_id
        }
      })
    )
      .done(function(data) {
        this.setState({ id: data.id, isSubmitted: true, isProcessing: false });
      })
      .fail(err => {
        this.setState({ isProcessing: false });
        displayError({ message: 'Error Creating Rate Group', err });
      });
  };

  openModal = () => {
    this.setState({
      reveal: true
    });
  };

  closeModal = () => {
    this.setState({
      reveal: false
    });
  };

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    this.setState(stateChange, () => {});
  };

  handleSubportfolioSelect = selectedOption => {
    this.setState({ subportfolio_id: selectedOption.id }, () => {});
  };

  handlePortfolioSelect = selectedOption => {
    this.setState({ portfolio_id: selectedOption.id }, () => {});
  };

  render() {
    if (this.state.isSubmitted) {
      return (
        <Redirect
          to={{
            pathname: `/rate-groups/${this.state.id}`,
            state: { isOpen: true, rateGroupId: this.state.id }
          }}
        />
      );
    }

    return (
      <>
        <PopoverForm
          triggerType={
            this.props.triggerChange ? 'icon-button' : 'call-to-action-button'
          }
          triggerProps={
            this.props.triggerChange
              ? { size: 'dense' }
              : { prependIcon: <IconFontAwesome name="plus" /> }
          }
          triggerLabel={
            this.props.triggerChange ? (
              <IconFontAwesome name="plus" />
            ) : (
              'Create'
            )
          }
          primaryActionProps={{
            actionType: 'button',
            action: () => this.onSubmit(),
            label: 'Create',
            isDisabled: this.state.isProcessing
          }}
          dropdownAlignment="bottomRight"
          showSpinner={this.state.isProcessing}
        >
          <AddRateGroupModal
            organization={this.props.organization}
            onChange={this.onChange}
            handleSubportfolioSelect={this.handleSubportfolioSelect}
            handlePortfolioSelect={this.handlePortfolioSelect}
            name={this.state.name}
            portfolio_id={this.state.portfolio_id}
            subportfolio_id={this.state.subportfolio_id}
          />
        </PopoverForm>
      </>
    );
  }
}
