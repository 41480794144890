/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import moment from 'moment';
import { OrganizationContext } from 'adminContexts';
import SubportfolioSelect from 'adminComponents/Selects/SubportfolioSelect';
import PortfolioSelect from 'adminComponents/Selects/PortfolioSelect';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import {
  Box,
  Divider,
  FlexBox,
  FormField,
  InputSwitch,
  Label,
  RadioButtonGrid
} from '@directsoftware/ui-kit-web-admin';
import selectOptions from '../FormElements/selectOptions';

export default class PromotionForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      travelStartDate: props.travel_date_start,
      travelEndDate: props.travel_end_date,
      promoStartDate: props.promo_start_date,
      promoEndDate: props.promo_end_date,
      travelFocusedInput: null,
      promoFocusedInput: null
    };
  }

  setSubportfolioDefaultValue = options => {
    return options.filter(
      option => option.value === this.props.subportfolio_id
    );
  };

  setPortfolioDefaultValue = options => {
    return options.filter(option => option.value === this.props.portfolio_id);
  };

  handlePortfolioSelect = option => {
    this.props.onUpdate({ portfolio_id: option.id });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.travel_date_start !== this.state.travelStartDate) {
      this.setState({ travelStartDate: nextProps.travel_date_start });
    }
    if (nextProps.travel_end_date !== this.state.travelEndDate) {
      this.setState({ travelEndDate: nextProps.travel_end_date });
    }
    if (nextProps.promo_start_date !== this.state.promoStartDate) {
      this.setState({ promoStartDate: nextProps.promo_start_date });
    }
    if (nextProps.promo_end_date !== this.state.promoEndDate) {
      this.setState({ promoEndDate: nextProps.promo_end_date });
    }
  };

  render() {
    const {
      special_type,
      amount,
      req_nights,
      travel_date_start,
      travel_end_date,
      promo_start_date,
      promo_end_date,
      name,
      internal_name,
      portfolio_id,
      subportfolio_id,
      active,
      code_req,
      coupon_code,
      onUpdate
    } = this.props;

    return (
      <div>
        <FlexBox alignItems="center">
          <Box flex="1">
            <Label htmlFor="active_status">
              {active
                ? 'This Promotion is Active'
                : 'This Promotion is Inactive'}
            </Label>
          </Box>
          <Box>
            <InputSwitch
              removeBottomSpacer
              onChange={() => onUpdate({ active: !active })}
              isChecked={active}
              id="active_status"
            />
          </Box>
        </FlexBox>
        <Divider padding="s" />

        <FormField
          labelText="Promotion Name"
          labelHtmlFor="PROMOTIONNAME"
          inputType="text"
          inputProps={{
            onChange: e =>
              onUpdate({
                name: e.target.value
              }),
            value: name || ''
          }}
        />
        <FormField
          labelText="Internal Name"
          labelHtmlFor="internal_name"
          inputType="text"
          inputProps={{
            onChange: e =>
              onUpdate({
                internal_name: e.target.value
              }),
            value: internal_name || ''
          }}
        />

        <Divider padding="s" />

        <FormField
          labelText="Type"
          inputType="select"
          inputProps={{
            name: 'special_type',
            options: selectOptions.special.special_type,
            value: find(selectOptions.special.special_type, {
              value: special_type
            }),
            onChange: option => {
              onUpdate({ special_type: option.value });
            },
            inputWidth: 's'
          }}
        />
        {this.props.special_type === 'percent' ? (
          <FormField
            labelText="Special Amount"
            labelHtmlFor="DISOCUNT APPLIED"
            inputType="number"
            inputProps={{
              name: 'SPECIAL AMOUNT',
              onChange: amount => {
                const value =
                  amount.target.value < 0
                    ? Math.abs(amount.target.value)
                    : amount.target.value;
                onUpdate({ amount: value });
              },
              max: '99999',
              value: amount,
              addSuffix: '%',
              inputWidth: 's'
            }}
          />
        ) : (
          <FormField
            labelText="Special Amount"
            labelHtmlFor="DISOCUNT APPLIED"
            inputType="currency"
            inputProps={{
              name: 'SPECIAL AMOUNT',
              onValueChange: amountValue => onUpdate({ amount: amountValue }),
              max: '99999',
              value: amount
            }}
          />
        )}
        <PortfolioSelect
          onSelect={this.handlePortfolioSelect}
          name="portfolio_id"
          label="Parent Portfolio"
          defaultValue={options => this.setPortfolioDefaultValue(options)}
        />
        {portfolio_id ? (
          <SubportfolioSelect
            onSelect={option => onUpdate({ subportfolio_id: option.id })}
            label="Parent Sub Portfolio"
            name="subportfolio_id"
            defaultValue={options => this.setSubportfolioDefaultValue(options)}
            defaultSelectData={{
              where: [`portfolio_id = ${portfolio_id}`],
              select: ['id', 'name'],
              order: ['name']
            }}
          />
        ) : (
          <></>
        )}

        <Divider padding="s" />

        <FormField
          labelText="Bookable Dates"
          inputType="date-range"
          inputProps={{
            name: 'promo_dates',
            onDatesChange: ({ startDate, endDate }) => {
              if (startDate && endDate) {
                this.setState(
                  {
                    promoStartDate: startDate,
                    promoEndDate: endDate
                  },
                  onUpdate({
                    promo_date_start: startDate.format('YYYY-MM-DD'),
                    promo_end_date: endDate.format('YYYY-MM-DD')
                  })
                );
              } else if (startDate && isNull(endDate)) {
                this.setState(
                  {
                    promoStartDate: startDate
                  },
                  onUpdate({
                    promo_start_date: startDate.format('YYYY-MM-DD')
                  })
                );
              }
            },
            onFocusChange: focusedInput =>
              this.setState({ promoFocusedInput: focusedInput }),
            startDate: moment(this.state.promoStartDate),
            endDate: moment(this.state.promoEndDate),
            focusedInput: this.state.promoFocusedInput,
            description:
              'Select the dates when this promotion is able to be booked',
            numberOfMonths: 1
          }}
        />
        <FormField
          labelText="Travel Dates"
          inputType="date-range"
          inputProps={{
            name: 'travel_dates',
            appendToBody: true,
            onDatesChange: ({ startDate, endDate }) => {
              if (startDate && endDate) {
                this.setState(
                  {
                    travelStartDate: startDate,
                    travelEndDate: endDate
                  },
                  onUpdate({
                    travel_date_start: startDate.format('YYYY-MM-DD'),
                    travel_end_date: endDate.format('YYYY-MM-DD')
                  })
                );
              } else if (startDate && isNull(endDate)) {
                this.setState(
                  {
                    travelStartDate: startDate
                  },
                  onUpdate({
                    travel_date_start: startDate.format('YYYY-MM-DD')
                  })
                );
              }
            },
            onFocusChange: focusedInput =>
              this.setState({ travelFocusedInput: focusedInput }),
            startDate: moment(this.state.travelStartDate),
            endDate: moment(this.state.travelEndDate),
            focusedInput: this.state.travelFocusedInput,
            description:
              'Select the date your unit(s) are available for travelers to stay at your promotional rate',
            numberOfMonths: 1
          }}
        />

        <FormField
          labelText="Minimum Length Of Stay"
          labelHtmlFor="req_nights"
          inputType="number"
          placeholder="Enter Nights"
          inputProps={{
            onChange: e => onUpdate({ req_nights: e.target.value }),
            value: req_nights,
            inputWidth: 's'
          }}
        />
        <Divider padding="s" />
        <Label>Add a Coupon Code?</Label>
        <RadioButtonGrid
          radioButtons={[
            {
              label: 'Yes',
              checked: code_req,
              onClick: () => onUpdate({ code_req: true })
            },
            {
              label: 'No',
              checked: !code_req,
              onClick: () => onUpdate({ code_req: false })
            }
          ]}
          numberOfColumns="1"
        />
        {code_req && (
          <FormField
            labelText="Coupon Code"
            inputType="text"
            inputProps={{
              value: coupon_code || '',
              onChange: e =>
                onUpdate({
                  coupon_code: e.target.value
                }),
              inputWidth: 'm'
            }}
          />
        )}
      </div>
    );
  }
}

PromotionForm.contextType = OrganizationContext;

PromotionForm.defaultProps = {
  onUpdate: _data => null,
  special_type: 'flat',
  code_req: false,
  promo_start_date: moment(),
  promo_end_date: moment().add(30),
  travel_date_start: moment(),
  travel_end_date: moment(),
  name: '',
  internal_name: '',
  active: true,
  req_nights: '',
  amount: ''
};
