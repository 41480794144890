import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentAccountService } from 'adminApi';
import { selectUserOrganization } from 'src/admin/redux/slices/userDetails';
import ListPortfoliosCommandPalette from 'src/admin/pages/DashboardPortfolio/molecules/list-portfolios-command-palette';
import { useDetectMobile } from 'src/shared/hooks';
import { updateListings } from 'src/admin/redux/slices/listings';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';

const Portfolios = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const organization = useSelector(selectUserOrganization);
  const [trustAccounts, setTrustAccounts] = useState([]);

  const loadTrustAccounts = () => {
    PaymentAccountService.index(props.organization.id, '?connected=true').then(
      response => {
        setTrustAccounts(response.payment_accounts);
      }
    );
  };

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PORTFOLIOS
      })
    );
    loadTrustAccounts();
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Listings: <span>Portfolios</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <ListPortfoliosCommandPalette
          organization={organization}
          trustAccounts={trustAccounts}
          loadPortfolios={() =>
            dispatch(updateListings({ refreshPortfolios: true }))
          }
          changeTrigger={isMobile}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default Portfolios;
