import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import AddUnitGroupButton from 'src/admin/pages/DashboardUnitGroups/components/AddUnitGroupButton';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const UnitGroups = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.UNIT_GROUPS
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Listings: <span>Unit Groups</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <AddUnitGroupButton organization={props.organization} />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default UnitGroups;
