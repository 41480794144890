import React from 'react';
import PortalModal from 'sharedComponents/PortalModal';
import displayError from 'sharedComponents/ErrorDisplay';
import { ajaxDataWithAuthorization } from 'apiClient';
import { Redirect } from 'react-router-dom';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import AddUnitGroupModal from './AddUnitGroupModal';

const actionStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  bottom: 0,
  right: '16px',
  padding: '8px 0 16px',
  backgroundColor: '#fff',
  boxShadow: '0px -8px 4px 4px rgb(255, 255, 255)'
};

export default class AddUnitGroupButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      id: null,
      subportfolio_id: null,
      portfolio_id: null,
      isSubmitted: false
    };
  }

  onSubmit = e => {
    e.preventDefault();
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/unit_groups/`,
        context: this,
        dataType: 'json',
        data: {
          name: this.state.name,
          subportfolio_id: this.state.subportfolio_id,
          portfolio_id: this.state.portfolio_id
        }
      })
    )
      .done(response => {
        this.setState({ isSubmitted: true, id: response.id });
      })
      .fail(err => displayError({ message: 'Error Creating Unit Group', err }));
  };

  onCancel = closePortal => {
    closePortal();
  };

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    this.setState(stateChange, () => {});
  };

  handleSubSelect = selectedOption => {
    this.setState({ subportfolio_id: selectedOption.id }, () => {});
  };

  handlePortfolioSelect = selectedOption => {
    this.setState(
      { portfolio_id: selectedOption.id, subportfolio_id: null },
      () => {}
    );
  };

  render() {
    if (this.state.isSubmitted) {
      return (
        <Redirect
          to={{
            pathname: `/unit-groups/${this.state.id}`,
            state: { isOpen: true, unitGroupId: this.state.id }
          }}
        />
      );
    }
    return (
      <section className="consolidated-item-command-palette">
        <div className="consolidated-item-command-palette-content">
          <header className="consolidated-item-command-palette-breadcrumbs" />
          <nav className="consolidated-item-command-palette-nav">
            <PortalModal
              openByClickOn={openPortal => (
                <>
                  <VisibilityHelper hideStartingFrom="tablet">
                    <IconButton onClick={openPortal} size="dense">
                      <IconFontAwesome name="plus" />
                    </IconButton>
                  </VisibilityHelper>
                  <VisibilityHelper showStartingFrom="tablet">
                    <CallToActionButton
                      prependIcon={<IconFontAwesome name="plus" />}
                      onClick={openPortal}
                    >
                      Create
                    </CallToActionButton>
                  </VisibilityHelper>
                </>
              )}
              submitAction={closePortal => (
                <CallToActionButton onClick={this.onSubmit}>
                  Create
                </CallToActionButton>
              )}
              cancelAction={closePortal => (
                <CallToActionButton
                  variation="secondary"
                  appearance="ghost"
                  onClick={() => this.onCancel(closePortal)}
                >
                  Cancel
                </CallToActionButton>
              )}
              actionStyles={actionStyles}
              header="New Unit Group"
              disableCloseOnOutsideClick
              closeOnSubmit
            >
              <AddUnitGroupModal
                organization={this.props.organization}
                onChange={this.onChange}
                portfolioId={this.state.portfolio_id}
                handleSubSelect={this.handleSubSelect}
                handlePortfolioSelect={this.handlePortfolioSelect}
                name={this.state.name}
              />
            </PortalModal>
          </nav>
        </div>
      </section>
    );
  }
}
