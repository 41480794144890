const selectOptions = {
  special: {
    special_type: [
      {
        label: 'Flat',
        value: 'flat'
      },
      {
        label: 'Percent',
        value: 'percent'
      },
      {
        label: 'Override',
        value: 'override'
      }
    ],
    code_req: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ]
  }
};

export default selectOptions;
