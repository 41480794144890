import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/subportfolios`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      rows: response.subportfolios,
      pages: Math.ceil(response.count / limit),
      totalCount: response.count
    };
  });
}
function get(orgId, subportfolioId) {
  return request({
    url: `/api/${orgId}/subportfolios/${subportfolioId}`,
    method: "GET"
  });
}
function units(orgId, subportfolioId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/subportfolios/${subportfolioId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function removeUnit(orgId, subportfolioId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/subportfolios/${subportfolioId}/remove_unit`,
    method: "PATCH",
    data: {
      unit_id: unitId,
      unit: !!unitType
    }
  });
}
function unlinked(orgId, portfolioId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/subportfolios/${portfolioId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function addUnit(orgId, subportfolioId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/subportfolios/${subportfolioId}/add_unit`,
    method: "POST",
    data: {
      unit_id: unitId,
      unit: !!unitType
    }
  });
}
const SubportfolioService = {
  list,
  get,
  units,
  removeUnit,
  unlinked,
  addUnit
};
export default SubportfolioService;
