/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PromotionService from 'adminApi/PromotionService';
import moment from 'moment';
import {
  isNotNull,
  isNotNullOrZero,
  isNotNullOrEmptyString
} from 'sharedUtils';
import {
  CallToActionButton,
  IconFontAwesome,
  SlideoutPanel,
  IconButton
} from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';
import PromotionForm from './PromotionForm';

export default class AddPromosButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promotion: {
        special_type: 'flat',
        code_req: false,
        promo_start_date: moment(),
        promo_end_date: moment().add(30),
        travel_date_start: moment(),
        travel_end_date: moment(),
        name: '',
        internal_name: '',
        active: true,
        req_nights: null,
        amount: null,
        formCompleted: false
      }
    };
  }

  formCompleted = promotion => {
    const {
      promo_end_date,
      promo_start_date,
      travel_date_start,
      travel_end_date,
      amount,
      req_nights,
      name
    } = promotion;
    const submittable =
      isNotNull(promo_end_date) &&
      isNotNull(promo_start_date) &&
      isNotNull(travel_date_start) &&
      isNotNull(travel_end_date) &&
      isNotNullOrZero(amount) &&
      isNotNullOrZero(req_nights) &&
      isNotNullOrEmptyString(name);
    return submittable;
  };

  onUpdate = data => {
    const promotion = { ...this.state.promotion, ...data };
    const formCompleted = this.formCompleted(promotion);
    this.setState({ promotion, formCompleted });
  };

  onSubmit = () => {
    PromotionService.create(
      this.props.organization.id,
      this.state.promotion
    ).then(
      this.setState({ promotion: null, openModal: false }, () =>
        this.props.reloadPromotions()
      )
    );
  };

  closeModal = () => {
    this.setState({ openModal: false, promotion: null });
  };

  render() {
    return (
      <>
        {this.props.triggerChange ? (
          <IconButton
            onClick={() => {
              this.setState({ openModal: true });
            }}
            size="dense"
          >
            <IconFontAwesome name="plus" />
          </IconButton>
        ) : (
          <CallToActionButton
            onClick={() => {
              this.setState({ openModal: true });
            }}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionButton>
        )}
        <SlideoutPanel
          closeButtonOnClick={this.closeModal}
          title="Promotion Details"
          reveal={this.state.openModal}
          secondaryActionProps={{
            action: this.closeModal,
            actionType: 'button',
            label: 'Cancel'
          }}
          primaryActionProps={{
            action: () => {
              this.formCompleted(this.state.promotion)
                ? this.onSubmit()
                : toast.error('Please finish filling out the form');
            },
            actionType: 'button',
            label: 'Save'
          }}
        >
          <PromotionForm {...this.state.promotion} onUpdate={this.onUpdate} />
        </SlideoutPanel>
      </>
    );
  }
}

AddPromosButton.defaultProps = {
  addPromoButtonText: 'Add Promotion',
  addPromoButtonClass: 'button',
  containerClass: 'align-right'
};
