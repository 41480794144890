import React from 'react';

import SimpleSelect from './SimpleSelect';

const defaultSelectData = {
  model: 'Property',
  select: ['id', 'name'],
  order: 'name',
  where: ['multi_unit = ?', true]
};

const MultiUnitPropertySelect = props => (
  <SimpleSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={props.label}
    placeholder={'Select...'}
    name={props.name}
    isClearable={props.isClearable}
    isSearchable
    {...props}
  />
);

export default MultiUnitPropertySelect;

MultiUnitPropertySelect.defaultProps = {
  isClearable: true,
  label: 'Property',
  name: 'property_id'
};
