import styled from 'styled-components';

const FormRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 20px;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const FormHalf = styled.div`
  width: 38%;
  margin-right: 10%;
`;

const FormThird = styled.div`
  width: 18%;
  margin-right: 2em;
  @media only screen and (max-width: 700px) {
    width: 48%;
  }
`;

const FormFourth = styled.div`
  width: 12%;
  margin-right: 2em;
  @media only screen and (max-width: 700px) {
    width: 48%;
  }
`;

export { FormRow, FormHalf, FormThird, FormFourth };
