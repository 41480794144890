import React from 'react';
import SubportfolioSelect from 'adminComponents/Selects/SubportfolioSelect';
import PortfolioSelect from 'adminComponents/Selects/PortfolioSelect';
import { InputText } from '@directsoftware/ui-kit-web-admin';

export default props => {
  return (
    <div className="align-left">
      <form noValidate className={'not-editable-field'}>
        <div>
          <label htmlFor="name">Name</label>
          <InputText
            name="name"
            onChange={props.onChange}
            value={props.name}
          />
        </div>
        <div>
          <div className="select-wrapper magnified">
            <PortfolioSelect
              onSelect={props.handlePortfolioSelect}
              label="Parent Portfolio"
              defaultValue={options => options.filter(option => option.value === props.portfolio_id)}
            />
          </div>
          {props.portfolio_id ? (
            <div className="select-wrapper magnified">
              <SubportfolioSelect
                onSelect={props.handleSubportfolioSelect}
                label="Parent Sub Portfolio"
                defaultSelectData={{
                  where: [`portfolio_id = ${props.portfolio_id}`],
                  select: ['id', 'name'],
                  order: ['name']
                }}
                defaultValue={options => options.filter(option => option.value === props.subportfolio_id)}
              />
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};
