import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/rate_groups`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      rows: response.rate_groups,
      pages: Math.ceil(response.count / limit),
      totalCount: response.count
    };
  });
}
function get(orgId, rateGroupId) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}`,
    method: "GET"
  });
}
function units(orgId, rateGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function unitGroups(orgId, rateGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/unit_groups`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      rate_group_id: rateGroupId
    }
  }).then((response) => {
    return {
      unit_groups: response.unit_groups,
      units: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function removeUnit(orgId, rateGroupId, unitId) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/remove_unit`,
    method: "PATCH",
    data: { unit_id: unitId }
  });
}
function removeUnitGroup(orgId, rateGroupId, unitGroupId, className) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/remove_unit_group`,
    method: "PATCH",
    data: {
      unit_group_id: unitGroupId,
      klass_name: className
    }
  });
}
function unlinked(orgId, rateGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      unit_groups: response.unit_groups,
      units: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function addUnitGroup(orgId, rateGroupId, unitGroupId) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/add_unit_group`,
    method: "POST",
    data: { unit_group_id: unitGroupId }
  });
}
function addUnit(orgId, rateGroupId, unitId, className) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/add_unit`,
    method: "POST",
    data: {
      unit_id: unitId,
      rate_group_id: rateGroupId,
      klass_name: className
    }
  });
}
function rateGroupRanges(orgId, filtered, rateGroupId) {
  return request({
    url: `/api/${orgId}/rate_groups/${rateGroupId}/rate_group_ranges`,
    method: "GET",
    params: { filtered }
  }).then((response) => {
    return {
      rows: response.rate_group_ranges
    };
  });
}
const RateGroupService = {
  list,
  get,
  units,
  removeUnit,
  unlinked,
  addUnitGroup,
  addUnit,
  unitGroups,
  removeUnitGroup,
  rateGroupRanges
};
export default RateGroupService;
