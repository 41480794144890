import request from "apiClient";
function list(orgId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/portfolios`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.portfolios,
      pages: Math.ceil(response.count / limit),
      totalCount: response.count
    };
  });
}
function get(orgId, portfolioId) {
  return request({
    url: `/api/${orgId}/portfolios/${portfolioId}`,
    method: "GET"
  });
}
function addUnit(orgId, portfolioId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/portfolios/${portfolioId}/add_unit`,
    method: "POST",
    data: {
      unit_id: unitId,
      unit: !!unitType
    }
  });
}
function removeUnit(orgId, portfolioId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/portfolios/${portfolioId}/remove_unit`,
    method: "PATCH",
    data: {
      unit_id: unitId,
      unit: !!unitType
    }
  });
}
function unlinked(orgId, portfolioId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/portfolios/${portfolioId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function units(orgId, portfolioId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/portfolios/${portfolioId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
const PortfolioService = {
  get,
  list,
  units,
  unlinked,
  addUnit,
  removeUnit
};
export default PortfolioService;
