import map from "lodash/map";
import metadatas from "src/admin/components/AdditionalFeatureMetadata/metadatas";
import deepcopy from "deepcopy";
import { titleize } from "src/shared/utils/display-helpers";
const formatLocationAddress = (location) => {
  const firstAddressLine = `${[location.adr_street, location.adr_unit].join(" ")},`;
  const secondAddressLine = [
    location.adr_city,
    location.adr_state,
    location.adr_country,
    location.adr_postal_code
  ].join(", ");
  const emptyAddressSegment = (segment) => Array.from(new Set(segment.replace(/ /g, ""))).join() === ",";
  const removeLeadingSpaceBeforeComma = (str) => str.replace(/ \,/g, ",");
  return `<address>${!emptyAddressSegment(firstAddressLine) && removeLeadingSpaceBeforeComma(firstAddressLine)}<br />${!emptyAddressSegment(secondAddressLine) && removeLeadingSpaceBeforeComma(secondAddressLine)}</address>`;
};
const showExternalRVshareLink = (vehicle) => {
  return vehicle.external_id && vehicle.rvshare_channel_mapping && vehicle.rvshare_channel_mapping.status === "active" && vehicle.rvshare_channel_mapping.visible;
};
const metadataFeatures = (features) => {
  return metadatas.map((metadata) => {
    if (features[metadata.feature] && features[metadata.feature][metadata.key] && features[metadata.feature][metadata.key].value) {
      return {
        metadata,
        amenity: {
          ...features[metadata.feature][metadata.key],
          metadata: {
            ...features[metadata.feature][metadata.key].metadata,
            ...metadata
          }
        }
      };
    }
  }).filter((meta) => meta !== void 0);
};
const buildAmenityArray = (amenities, airbnbAmenities = []) => {
  return amenities ? map(Object.keys(amenities), (categoryKey) => {
    const amenityData = deepcopy(amenities[categoryKey]);
    if (categoryKey === "ACCOMMODATIONS_DINNER_NOT_AVAILABLE") {
      if (amenityData.label === "Lunch not available")
        amenityData.label = "Dinner not available";
    }
    return {
      key: categoryKey,
      isAirbnbRequired: airbnbAmenities.includes(categoryKey),
      ...amenityData
    };
  }).filter((amenity) => amenity.label !== "") : [];
};
const organizeAmenities = (props, airbnbAmenities) => {
  return {
    propertyFeatures: [
      {
        amenityGroupKey: "adventure",
        label: "Adventure",
        featureKey: "features_adventure",
        features: buildAmenityArray(props.features_adventure)
      },
      {
        amenityGroupKey: "attractions",
        label: "Attractions",
        featureKey: "features_attractions",
        features: buildAmenityArray(props.features_attractions)
      },
      {
        amenityGroupKey: "car",
        label: "Car",
        featureKey: "features_car",
        features: buildAmenityArray(props.features_car)
      },
      {
        amenityGroupKey: "leisure",
        label: "Leisure",
        featureKey: "features_leisure",
        features: buildAmenityArray(props.features_leisure)
      },
      {
        amenityGroupKey: "local",
        label: "Local",
        featureKey: "features_local",
        features: buildAmenityArray(props.features_local)
      },
      {
        amenityGroupKey: "location",
        label: "Location",
        featureKey: "features_location",
        features: buildAmenityArray(props.features_location)
      }
    ],
    lodgingFeatures: [
      {
        amenityGroupKey: "accommodations",
        label: "Accommodations",
        featureKey: "features_accommodations",
        features: buildAmenityArray(props.features_accommodations, airbnbAmenities)
      },
      {
        amenityGroupKey: "amenities",
        label: "Amenities",
        featureKey: "features_amenities",
        features: buildAmenityArray(props.features_amenities, airbnbAmenities)
      },
      {
        amenityGroupKey: "dining",
        label: "Dining",
        featureKey: "features_dining",
        features: buildAmenityArray(props.features_dining, airbnbAmenities)
      },
      {
        amenityGroupKey: "entertainment",
        label: "Entertainment",
        featureKey: "features_entertainment",
        features: buildAmenityArray(props.features_entertainment, airbnbAmenities)
      },
      {
        amenityGroupKey: "outdoor",
        label: "Outdoor",
        featureKey: "features_outdoor",
        features: buildAmenityArray(props.features_outdoor, airbnbAmenities)
      },
      {
        amenityGroupKey: "spa",
        label: "Spa",
        featureKey: "features_spa",
        features: buildAmenityArray(props.features_spa, airbnbAmenities)
      },
      {
        amenityGroupKey: "suitability",
        label: "Suitability",
        featureKey: "features_suitability",
        features: buildAmenityArray(props.features_suitability, airbnbAmenities)
      },
      {
        amenityGroupKey: "themes",
        label: "Themes",
        featureKey: "features_themes",
        features: buildAmenityArray(props.features_themes, airbnbAmenities)
      },
      {
        amenityGroupKey: "safety",
        label: "Safety",
        featureKey: "features_safety",
        features: buildAmenityArray(props.features_safety, airbnbAmenities)
      }
    ],
    additionalLodgingFeatures: metadataFeatures({
      features_accommodations: props.features_accommodations,
      features_amenities: props.features_amenities,
      features_dining: props.features_dining,
      features_entertainment: props.features_entertainment,
      features_outdoor: props.features_outdoor,
      features_spa: props.features_spa,
      features_suitability: props.features_suitability,
      features_themes: props.features_themes,
      features_safety: props.features_safety
    })
  };
};
const buildAmenitySaveObject = (amenityArray) => {
  let saveObject = {};
  amenityArray.forEach((obj) => {
    let innerObj = {};
    obj.features.forEach((obj2) => {
      const newObj = { ...obj2 };
      delete newObj.key;
      innerObj = {
        ...innerObj,
        [obj2.key]: newObj
      };
    });
    saveObject = {
      ...saveObject,
      [obj.amenityGroupKey]: innerObj
    };
  });
  return saveObject;
};
const saveMetadata = (metadataFeature, key, value, isArray, metadata) => {
  var _a;
  const newMetadata = metadata ? deepcopy(metadata) : deepcopy(metadataFeature.metadata);
  const newMetadataKey = metadataFeature.metadata.value;
  if (newMetadata[newMetadataKey]) {
    if (isArray) {
      if (!newMetadata[newMetadataKey][key]) {
        newMetadata[newMetadataKey][key] = [];
      }
      const indexExists = (_a = newMetadata[newMetadataKey][key]) == null ? void 0 : _a.findIndex((nm) => nm === value);
      if (indexExists >= 0)
        newMetadata[newMetadataKey][key].splice(indexExists, 1);
      newMetadata[newMetadataKey] = {
        ...newMetadata[newMetadataKey],
        [key]: indexExists >= 0 ? newMetadata[newMetadataKey][key] : [...newMetadata[newMetadataKey][key], value]
      };
    } else {
      newMetadata[newMetadataKey] = {
        ...newMetadata[newMetadataKey],
        [key]: value
      };
    }
  } else {
    newMetadata[newMetadataKey] = {
      [key]: isArray ? [value] : value
    };
  }
  return newMetadata;
};
const hasAdditionalFeatures = (amenity) => {
  const additionalFeatures = metadatas.map((metadata) => {
    if (metadata.key === amenity.key) {
      return {
        metadata,
        amenity: {
          ...amenity,
          metadata: {
            ...amenity.metadata,
            ...metadata
          }
        }
      };
    }
  }).filter((item) => item !== void 0);
  if (additionalFeatures.length > 0) {
    return additionalFeatures[0];
  } else
    return false;
};
const renderAdditionalFeaturesLine = (amenity) => {
  var _a;
  if (amenity && amenity.metadata) {
    const metadata = amenity == null ? void 0 : amenity.metadata[(_a = amenity == null ? void 0 : amenity.metadata) == null ? void 0 : _a.value];
    if (metadata) {
      const textArray = [];
      Object.keys(metadata).forEach((feature) => {
        if (metadata[feature]) {
          if (Array.isArray(metadata[feature])) {
            metadata[feature].forEach((f) => {
              textArray.push(titleize(f));
            });
          } else if (feature === "size_in_inch")
            textArray.push(titleize(`${metadata[feature]} in.`));
          else
            textArray.push(titleize(metadata[feature]));
        }
      });
      return textArray.join(", ");
    }
    return null;
  }
  return null;
};
export {
  formatLocationAddress,
  showExternalRVshareLink,
  organizeAmenities,
  buildAmenitySaveObject,
  hasAdditionalFeatures,
  saveMetadata,
  renderAdditionalFeaturesLine
};
