import React from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';

export default class NewPortfolio extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  getValue = (paymentProcessor, trustAccounts) => {
    if (paymentProcessor === 'stripe')
      trustAccounts.filter(
        trust => trust.value === this.props.stripe_connect_account_id
      )[0] || null;
    if (paymentProcessor === 'lynnbrook')
      trustAccounts.filter(
        trust => trust.value === this.props.lynnbrook_account_id
      )[0] || null;
  };

  render() {
    const trustAccounts = this.props?.trustAccounts.map(opt => ({
      label: opt.display_name,
      value: opt.id
    }));

    const paymentProcessor = this.props.organization.payment_processor;

    return (
      <>
        <FormField
          labelText="Name"
          labelHtmlFor="name"
          inputProps={{
            value: this.props.name,
            name: 'name',
            onChange: e => this.props.onChange(e)
          }}
        />
        <FormField
          labelText="Trust Account"
          labelHtmlFor="trust_account"
          inputType="select"
          inputProps={{
            options: trustAccounts,
            value: this.getValue(paymentProcessor, trustAccounts),
            onChange: opt =>
              this.props.onChange({
                name:
                  paymentProcessor === 'stripe'
                    ? 'stripe_connect_account_id'
                    : 'lynnbrook_account_id',
                value: opt.value
              })
          }}
        />
      </>
    );
  }
}
