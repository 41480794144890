import React from 'react';
import PortfolioSelect from 'adminComponents/Selects/PortfolioSelect';
import { FormField } from '@directsoftware/ui-kit-web-admin';

export default class AddSubportfolioModal extends React.Component {
  setPortfolioDefaultValue = options => {
    if (options.length > 0)
      return options.filter(option => option.value === this.props.portfolioId);

    return null;
  };

  render() {
    return (
      <>
        <FormField
          labelText="Name"
          labelHtmlFor="name"
          inputType="text"
          inputProps={{
            onChange: e => this.props.onChange(e),
            value: this.props.name,
            name: 'name'
          }}
        />
        <PortfolioSelect
          label="Parent Portfolio"
          onSelect={this.props.handleSelect}
          defaultValue={options => this.setPortfolioDefaultValue(options)}
          useDesignKit
        />
      </>
    );
  }
}
