import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import AddRoomTypeButton from 'src/admin/pages/DashboardRoomTypes/components/AddRoomTypeButton';
import { useDetectMobile } from 'src/shared/hooks';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const RoomTypes = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.ROOM_TYPES
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Listings: <span>Room Types</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <AddRoomTypeButton
          organization={props.organization}
          triggerChange={isMobile}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default RoomTypes;
