import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import { Redirect } from 'react-router-dom';
import { IconFontAwesome, PopoverForm } from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';
import AddSubportfolioModal from './AddSubportfolioModal';

const actionStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  bottom: 0,
  right: '16px',
  padding: '8px 0 16px',
  backgroundColor: '#fff',
  boxShadow: '0px -8px 4px 4px rgb(255, 255, 255)'
};

export default class AddSubportfolioButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      portfolio_id: null,
      id: null,
      isSubmitted: false,
      openModal: false,
      showSpinner: false
    };
  }

  onSubmit = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/subportfolios/`,
        context: this,
        dataType: 'json',
        data: {
          name: this.state.name.trim(),
          portfolio_id: this.state.portfolio_id
        }
      })
    )
      .done(response => {
        this.setState({
          isSubmitted: true,
          id: response.id,
          showSpinner: false
        });
      })
      .fail(function(jqXHR) {
        const response = jqXHR.responseJSON;
        toast.error(response.errors);
      });
  };

  onCancel = closePortal => {
    closePortal();
  };

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    this.setState(stateChange, () => {});
  };

  handleSelect = selectedOption => {
    this.setState({ portfolio_id: selectedOption.id }, () => {});
  };

  render() {
    if (this.state.isSubmitted) {
      return (
        <Redirect
          to={{
            pathname: `/sub-portfolios/${this.state.id}`,
            state: { isOpen: true, subportfolioId: this.state.id }
          }}
        />
      );
    }
    return (
      <PopoverForm
        primaryActionProps={{
          action: () => {
            this.setState({ showSpinner: true });
            this.onSubmit();
          },
          label: 'Create Subportfolio',
          isDisabled: this.state.name === '' || !this.state.portfolio_id
        }}
        triggerLabel={
          this.props.triggerChange ? <IconFontAwesome name="plus" /> : 'Create'
        }
        triggerProps={
          this.props.triggerChange
            ? { size: 'dense' }
            : { prependIcon: <IconFontAwesome name="plus" /> }
        }
        triggerType={
          this.props.triggerChange ? 'icon-button' : 'call-to-action-button'
        }
        dropdownAlignment="bottomRight"
        showSpinner={this.state.showSpinner}
      >
        <AddSubportfolioModal
          onChange={this.onChange}
          handleSelect={this.handleSelect}
          name={this.state.name}
          portfolioId={this.state.portfolio_id}
        />
      </PopoverForm>
    );
  }
}
