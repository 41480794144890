import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import {
  FlexBox,
  TextCaption,
  VisibilityHelper,
  TabBar,
  Box,
  IconFontAwesome,
  IconButton
} from '@directsoftware/ui-kit-web-admin';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const legendData = [
  {
    key: 'available',
    label: 'Instant Bookable',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#ffffff' }} />
    )
  },
  {
    key: 'inquiry',
    label: 'Bookable by request',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#5794E4' }} />
    )
  },
  {
    key: 'unavailable',
    label: 'Unavailable',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#FB5872' }} />
    )
  },
  {
    key: 'custom',
    label: 'Custom availability set',
    LegendIcon: () => (
      <i
        className="key-item"
        style={{ backgroundColor: '#ffffff', position: 'relative' }}
      >
        <div className="calendar-day-info-edited" />
      </i>
    )
  }
];

function filterLegendData(legendKeys) {
  if (!legendKeys) return legendData;

  return legendData.filter(({ key }) => legendKeys.includes(key));
}

export default class CalendarStepper extends React.Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    startingStepIndex: PropTypes.number,
    legendKeys: PropTypes.array
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      calendar: this.props.calendar,
      currentStepIndex: this.props.startingStepIndex || 0,
      hintsVisible: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.currentStepIndex !== prevProps.currentStepIndex) {
      this.setStep(prevProps.currentStepIndex);
    }
  }

  setStep = currentStepIndex => {
    this.setState({
      currentStepIndex
    });
  };

  toggleHintsVisiblity = e => {
    e.preventDefault();
    const hintsVisible = this.state.hintsVisible;

    this.setState({
      hintsVisible: !hintsVisible
    });
  };

  jumpToStep = (e, stepIndex) => {
    if (e) e.preventDefault();
    const { steps } = this.props;
    const currentStep = steps[this.state.currentStepIndex];
    if (
      stepIndex > this.state.currentStepIndex &&
      currentStep.handleIncrement
    ) {
      currentStep.handleIncrement.function(currentStep.handleIncrement.params);
      return;
    } else if (
      stepIndex < this.state.currentStepIndex &&
      currentStep.handleDecrement
    ) {
      currentStep.handleDecrement.function(currentStep.handleDecrement.params);
      return;
    }
    this.props.setStepIndex(stepIndex);

    this.setState({ currentStepIndex: stepIndex });
  };

  onSelectStep = e => {
    const stepIndex = parseInt(e.target.value);
    this.jumpToStep(e, stepIndex);
  };

  renderStepLabels() {
    const steps = this.props.steps;
    const currentStep = steps[this.state.currentStepIndex];
    return (
      <>
        <MediaQuery maxWidth={639}>
          <header className="stepper-step-labels">
            <div className="select-wrapper">
              <select
                value={this.state.currentStepIndex}
                name="current_step"
                onChange={this.onSelectStep}
                disabled={currentStep.disabled}
              >
                {steps.map(step => (
                  <option key={step.index} value={step.index}>
                    {step.label}
                  </option>
                ))}
              </select>
            </div>
          </header>
        </MediaQuery>
        <MediaQuery minWidth={640} maxWidth={767}>
          <header className="stepper-step-labels">
            <ul>
              {steps.map(step => (
                <li
                  key={step.index}
                  className={
                    step.index === this.state.currentStepIndex ? 'active' : ''
                  }
                >
                  <a
                    href="#"
                    className={classNames({ disabled: currentStep.disabled })}
                    onClick={e => this.jumpToStep(e, step.index)}
                  >
                    {step.label}
                  </a>
                </li>
              ))}
            </ul>
          </header>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <Box className="newAvailabilityCalendar__tabBar">
            <Box setPositionRelative>
              <TabBar minimalist containerVariation="full-width">
                {steps.map(step => (
                  <TabBar.Tab
                    minimalist
                    onClick={() => this.jumpToStep(null, step.index)}
                    isActive={step.index === this.state.currentStepIndex}
                  >
                    {step.label}
                  </TabBar.Tab>
                ))}
              </TabBar>
              <Box className="newAvailabilityCalendar__tabBar_close">
                <IconButton
                  onClick={() => this.props.toggleEditingForm(null, true)}
                  variation="secondary"
                  appearance="ghost"
                >
                  <IconFontAwesome name="times" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </MediaQuery>
      </>
    );
  }

  render() {
    const { steps, calendar } = this.props;
    const currentStep = steps[this.state.currentStepIndex];

    return (
      <>
        <VisibilityHelper showStartingFrom="tablet">
          <FlexBox className="newAvailabilityCalendar">
            <FlexBox
              className="newAvailabilityCalendar__left"
              flexDirection="column"
            >
              <FlexBox
                alignItems="center"
                gap="s"
                className="newAvailabilityCalendar__legend"
                paddingHorizontal="s"
              >
                {filterLegendData(this.props.legendKeys).map(
                  ({ key, label, LegendIcon }) => (
                    <FlexBox
                      key={key}
                      alignItems="center"
                      gap="xs"
                      className="newAvailabilityCalendar__legend_icon"
                    >
                      <LegendIcon />
                      <TextCaption>{label}</TextCaption>
                    </FlexBox>
                  )
                )}
              </FlexBox>
              {calendar}
            </FlexBox>
            <FlexBox className="newAvailabilityCalendar__right">
              <FlexBox
                key={currentStep.index}
                flex="1"
                justifyContent={
                  this.props.editingForm ? 'flex-start' : 'center'
                }
                alignItems={this.props.editingForm ? 'flex-start' : 'center'}
                flexDirection="column"
              >
                {this.props.showStepLabels && this.props.editingForm
                  ? this.renderStepLabels()
                  : null}
                {currentStep.component}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </VisibilityHelper>
        <VisibilityHelper hideStartingFrom="tablet">
          <figure className="stepper">
            <ReactCSSTransitionGroup
              transitionName="stepper-transition"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={1}
              component="main"
            >
              <div key={currentStep.index}>
                {this.props.showStepLabels ? this.renderStepLabels() : null}
                <section className="wrapper">
                  {this.props.title ? (
                    <section className="consolidated-item-command-palette">
                      <div className="consolidated-item-command-palette-content">
                        <header className="consolidated-item-command-palette-breadcrumbs">
                          <span className="consolidated-item-command-palette-heading">
                            {this.props.title}
                          </span>
                        </header>
                      </div>
                    </section>
                  ) : null}
                  {currentStep.component}
                </section>
              </div>
            </ReactCSSTransitionGroup>
            {!this.props.hideCalendar && (
              <MediaQuery query="(max-width: 799px)">
                {!this.props.editingForm && (
                  <aside>
                    <figure className="card" style={{ margin: 0 }} key={0}>
                      {calendar}
                    </figure>
                  </aside>
                )}
              </MediaQuery>
            )}
            <MediaQuery query="(min-width: 800px)">
              <ReactCSSTransitionGroup
                transitionName="stepper-transition"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={1}
                component="aside"
              >
                <div>
                  <section className="consolidated-key">
                    <ul>
                      {filterLegendData(this.props.legendKeys).map(
                        ({ key, label, LegendIcon }) => (
                          <li key={key}>
                            <LegendIcon />
                            <span className="key-descriptor">{label}</span>
                          </li>
                        )
                      )}
                    </ul>
                  </section>
                  <figure className="card" style={{ margin: 0 }} key={0}>
                    {calendar}
                  </figure>
                </div>
              </ReactCSSTransitionGroup>
            </MediaQuery>
          </figure>
        </VisibilityHelper>
      </>
    );
  }
}
