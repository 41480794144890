/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { OrganizationContext } from 'adminContexts';
import styled from 'styled-components';
import MultiUnitPropertySelect from 'adminComponents/Selects/MultiUnitPropertySelect';
import { FormRow, FormHalf } from '../FormElements/FormStyles';
import Spinner from 'adminComponents/spinner';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const inputStyles = {
  height: '3em',
  width: '10em',
  fontSize: '14px',
  color: '#333',
  fontWeight: '400',
  paddingLeft: '5px'
};
const dateStyles = {
  height: '2.3em',
  width: '20em',
  fontSize: '16px'
};
const selectStyles = {
  height: '2.5em',
  width: '5em'
};
const labelStyles = {
  fontSize: '16px'
};

export default class RoomTypeForm extends React.PureComponent {
  setPropertyDefaultValue = options => {
    return options.filter(option => option.value === this.props.property_id);
  };

  render() {
    const {
      name,
      bedroom_count,
      bathroom_count,
      guest_count,
      bed_config,
      category,
      default_availability_changeover,
      default_stay_max,
      default_stay_min,
      deafault_nightly_weekday,
      default_nightly_weekend,
      default_prior_notify_min,
      discount_full_month,
      discount_full_week,
      property_id,
      onUpdate,
      isLoading
    } = this.props;

    const typeOptions = [
      { value: 'apartment', label: 'Apartment' },
      { value: 'bungalow', label: 'Bungalow' },
      { value: 'chalet', label: 'Chalet' },
      { value: 'dormitory', label: 'Dormitory' },
      { value: 'double', label: 'Double' },
      { value: 'family', label: 'Family' },
      { value: 'holiday_home', label: 'Holiday Home' },
      { value: 'mobile_home', label: 'Mobile Home' },
      { value: 'single', label: 'Single' },
      { value: 'studio', label: 'Studio' },
      { value: 'suite', label: 'Suite' },
      { value: 'tent', label: 'Tent' },
      { value: 'triple', label: 'Triple' },
      { value: 'twin', label: 'Twin' },
      { value: 'quadruple', label: 'Quadruple' },
      { value: 'vila', label: 'Vila' }
    ];

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <div style={{ textAlign: 'left', paddingBottom: '32px' }}>
        <label style={labelStyles}>ROOM SETTINGS</label>
        <FormRow>
          <FormHalf>
            <MultiUnitPropertySelect
              onSelect={option => onUpdate({ property_id: option.id })}
              name="property_id"
              label="Parent Property"
              defaultValue={options => this.setPropertyDefaultValue(options)}
            />
          </FormHalf>
          <FormHalf>
            <label>NAME</label>
            <InputContainer>
              <input
                name="name"
                value={name}
                onChange={e =>
                  onUpdate({
                    name: e.target.value
                  })
                }
                style={inputStyles}
                placeholder="Enter Name..."
              />
            </InputContainer>
          </FormHalf>
        </FormRow>
        <label>Type</label>
        <FormRow>
          <InputSelect
            name="bed_config"
            placeholder="Type"
            options={typeOptions}
            searchable
            isClearable
            value={typeOptions.filter(e => e.value === bed_config)}
            onChange={e => onUpdate({ bed_config: e.value })}
          />
        </FormRow>
      </div>
    );
  }
}

RoomTypeForm.contextType = OrganizationContext;

RoomTypeForm.defaultProps = {
  onUpdate: _data => null,
  name: null,
  isLoading: false
};
