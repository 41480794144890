/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PortalModal from 'sharedComponents/PortalModal';
import RoomTypeService from 'adminApi/RoomTypeService';
import { Redirect } from 'react-router-dom';
import displayError from 'sharedComponents/ErrorDisplay';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome
} from '@directsoftware/ui-kit-web-admin';
import RoomTypeForm from './RoomTypeForm';

export default class AddRoomTypeButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roomType: {
        name: null,
        bedroom_count: null,
        bathroom_count: null,
        guest_count: null,
        bed_config: null,
        category: null,
        default_availability_changeover: null,
        default_stay_max: null,
        default_stay_min: null,
        deafault_nightly_weekday: null,
        default_nightly_weekend: null,
        default_prior_notify_min: null,
        discount_full_month: null,
        discount_full_week: null,
        property_id: null,
        isLoading: false
      }
    };
  }

  onUpdate = data => {
    const roomType = { ...this.state.roomType, ...data };
    this.setState({ roomType });
  };

  onSubmit = closePortal => {
    this.setState({ isLoading: true });
    RoomTypeService.create(this.props.organization.id, this.state.roomType)
      .then(response => this.setState({ roomType: response, submitted: true }))
      .catch(() => {
        this.setState({ isLoading: false }, () => {
          displayError({ message: 'unable to create room type' });
        });
      });
  };

  onCancel = closePortal => {
    this.setState({ roomType: null });
    closePortal();
  };

  submitAction = closePortal => {
    return (
      <CallToActionButton onClick={() => this.onSubmit(closePortal)}>
        Create
      </CallToActionButton>
    );
  };

  cancelAction = closePortal => {
    return (
      <CallToActionButton
        appearance="ghost"
        variation="secondary"
        onClick={() => this.onCancel(closePortal)}
      >
        Cancel
      </CallToActionButton>
    );
  };

  render() {
    if (this.state.submitted) {
      return (
        <Redirect
          to={{
            pathname: `/room-types/${this.state.roomType.id}`
          }}
        />
      );
    }
    return (
      <div className={`${this.props.containerClass}`}>
        <PortalModal
          openByClickOn={openPortal => {
            if (this.props.triggerChange) {
              return (
                <IconButton onClick={openPortal} size="dense">
                  <IconFontAwesome name="plus" />
                </IconButton>
              );
            }
            return (
              <CallToActionButton
                prependIcon={<IconFontAwesome name="plus" />}
                onClick={openPortal}
              >
                Create
              </CallToActionButton>
            );
          }}
          header="Rooms Settings"
          disableCloseOnOutsideClick
          submitAction={closePortal => this.submitAction(closePortal)}
          cancelAction={closePortal => this.cancelAction(closePortal)}
          closeOnSubmit
          actionStyles={{ display: 'flex', justifyContent: 'flex-end' }}
          modalStyles={{ width: '45%' }}
        >
          <RoomTypeForm
            {...this.state.roomType}
            organization={this.props.organization}
            onUpdate={this.onUpdate}
            isLoading={this.state.isLoading}
          />
        </PortalModal>
      </div>
    );
  }
}

AddRoomTypeButton.defaultProps = {
  addRoomTypeButtonText: 'Add roomType',
  addRoomTypeButtonClass: 'button',
  containerClass: 'align-right'
};
