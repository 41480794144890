const Metadatas = [
  {
    key: "KITCHEN_DINING_KITCHEN",
    feature: "features_dining",
    value: "kitchen",
    options: [
      {
        label: "Kitchen Type",
        value: "kitchen_type",
        type: "radioGrid",
        options: [
          { label: "Full Kitchen", value: "full_kitchen" },
          { label: "Kitchenette", value: "kitchenette" }
        ]
      }
    ]
  },
  {
    key: "KITCHEN_DINING_DINING_TABLE",
    feature: "features_dining",
    value: "dining_table",
    options: [
      {
        label: "Number of Seats",
        value: "number_of_spaces",
        type: "integer",
        options: [],
        suffix: "seats"
      }
    ]
  },
  {
    key: "ENTERTAINMENT_TELEVISION",
    feature: "features_entertainment",
    value: "tv",
    options: [
      {
        label: "Television Size",
        value: "size_in_inch",
        type: "integer",
        options: [],
        suffix: "inches"
      },
      {
        label: "Television Quality",
        value: "quality",
        type: "dropdown",
        options: [
          { label: "HD", value: "HD" },
          { label: "STANDARD", value: "STANDARD" }
        ]
      },
      {
        label: "Television Services",
        value: "services",
        type: "checkbox",
        options: [
          { label: "Amazon Prime Video", value: "amazon" },
          { label: "Apple TV", value: "apple_tv" },
          { label: "Chromecast", value: "chromecast" },
          { label: "Disney+", value: "disney_plus" },
          { label: "Fire TV", value: "fire_tv" },
          { label: "HBO Max", value: "hbo_go" },
          { label: "Hulu", value: "hulu" },
          { label: "Netflix", value: "netflix" },
          { label: "Premium cable", value: "premium_cable" },
          { label: "Roku", value: "roku" },
          { label: "Standard cable", value: "standard_cable" }
        ]
      }
    ]
  },
  {
    key: "KITCHEN_DINING_COFFEE_MAKER",
    feature: "features_dining",
    value: "coffee_maker",
    options: [
      {
        label: "Coffee Maker Type",
        value: "coffee_maker_types",
        type: "checkbox",
        options: [
          { label: "Drip coffee maker", value: "DRIP_COFFEE" },
          { label: "Espresso machine", value: "ESPRESSO_MACHINE" },
          { label: "French press", value: "FRENCH_PRESS" },
          { label: "Keurig", value: "KEURIG" },
          { label: "Nespresso", value: "NESPRESSO" },
          { label: "Pour over coffee", value: "POUR_OVER_COFFEE" }
        ]
      }
    ]
  },
  {
    key: "AMENITIES_EXERCISE_EQUIPMENT",
    feature: "features_amenities",
    value: "exercise_equipment",
    options: [
      {
        label: "Exercise Equipment Type",
        value: "equipment_types",
        type: "checkbox",
        options: [
          { label: "Elliptical", value: "elliptical" },
          { label: "Free weights", value: "free_weights" },
          { label: "Stationary bike", value: "stationary_bike" },
          { label: "Treadmill", value: "treadmill" },
          { label: "Yoga mat", value: "yoga_mat" },
          { label: "Rowing", value: "rowing" },
          { label: "Workout bench", value: "workout_bench" }
        ]
      }
    ]
  }
];
export default Metadatas;
