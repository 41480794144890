import React from 'react';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';

import { ReadContainer, InputEditContainer } from './InlineTextEditStyles';

export default class EditableInlineInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editValue: props.defaultValue
    };
  }

  onConfirm = editValue => {
    this.setState({ editValue }, () => {
      this.props.onConfirm(editValue);
    });
  };

  renderReadView = () => {
    if (this.props.useEditViewAsReadView) {
      return (
        <InputEditContainer>
          <Textfield
            defaultValue={this.state.editValue}
            name={'edit-read-view'}
          />
        </InputEditContainer>
      );
    }

    return (
      <ReadContainer>{this.props.readView(this.state.editValue)}</ReadContainer>
    );
  };

  renderDisabledView = () => {
    return (
      <InputEditContainer>
        <Textfield
          defaultValue={this.state.editValue}
          name={'edit-read-view'}
          isDisabled
        />
      </InputEditContainer>
    );
  };

  render() {
    const editValue = this.state.editValue;

    if (this.props.isDisabled) return this.renderDisabledView();

    return (
      <InlineEdit
        defaultValue={editValue}
        editView={fieldProps => (
          <InputEditContainer>
            <Textfield {...fieldProps} autoFocus autoComplete="off" />
          </InputEditContainer>
        )}
        readView={this.renderReadView}
        onConfirm={this.onConfirm}
        readViewFitContainerWidth
        hideActionButtons={this.props.hideActionButtons}
        validate={this.props.validate}
      />
    );
  }
}

EditableInlineInput.defaultProps = {
  readView: value => value,
  hideActionButtons: false
};
