import React from 'react';

import SimpleSelect from './SimpleSelect';

const defaultSelectData = {
  model: 'Subportfolio',
  select: ['id', 'name']
};

const SubportfolioSelect = props => (
  <SimpleSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={props.label}
    placeholder={'Select...'}
    name={'Subportfolio_select'}
    {...props}
  />
);

export default SubportfolioSelect;

SubportfolioSelect.defaultProps = {
  label: 'Sub Portfolio'
};
